/*
This code is property of Risk Laboratories, LLC.
Copyright 2002 Risk Laboratories, LLC. All Rights Reserved.
Unauthorized duplication of this code is strictly prohibited.
*/
body {
    font-family: "Segoe UI Light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", helvetica, tahoma, arial, sans-serif;
    background-color: #ffffff !important;
}

html, body {
    -webkit-font-smoothing: antialiased;
    height: 90%;
}

form {
    font-size: inherit;
}

.outer-login-div {
    position:absolute;
    height: 400px;
    top: 25%;
    left: 33%;
}

.fontclass {
    font-family: "Segoe UI Light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", helvetica, tahoma, arial, sans-serif;
    font-size:x-small;
}

.label {
    font-family: "Segoe UI Light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", helvetica, tahoma, arial, sans-serif;
    font-size:x-small;
    font-weight: bold;
    text-align:right;
    padding-right: 0;
    width:170px
}

.input-label {
    text-align: right;
}

.field { padding-top:5px }

.button { padding:10px }

.form-signin {
    font-family: "Segoe UI", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
}

.instructional {
    background-color: #002834;
    background-image: url('/Alpha/static/custom/login/images/risk_inc-logo.png');
    background-position: 50px center;
    background-repeat: no-repeat;
    color: white;
    font-size: 18pt;
    font-weight: 100;
    position:relative;
    padding: 15px;
    padding-left: 40px;
    padding-top: 40px;
    text-align: left;
    margin-left: -1px;
    margin-right: -1px;
    height: 25px;
}

.login-input-div {
    text-align: left;
}

.login-input {
    width: 100%;
}

.password-row {
    margin-top: 10px;
}

.loginPasswordStyle{
    margin-bottom: 10px
}
.loginInputDiv .loginInput {
    height: 30px;
    border-radius: 0;
    font-size: 12pt;
    width: 300px;
    padding: 1px;
    padding-left: 10px;
    padding-right: 10px;
}
.loginInput{
    width:100%;
}
.loginInputDiv {
    display: inline-block;
    padding-top: 30px;
}

.login-buttons-div {
    margin-top: 30px;
}

.forgotPwdDiv {
    padding: 10px;
}

.privacyDiv {
    padding: 10px;
}


.loginOuterTable {
    background-color: #e7f5ff;
    display: inline-block;
    padding-top: 10px;
    height: 420px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.pid-style{
    text-align: right;
}
.uid-style{
    text-align: right;
}
.privacy-statement {
    font-size: 16px;
    font-family: "Segoe UI Light", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 300;
    text-align: center;
}
label{
    display: block;
    line-height: 20px;
}

a {
    color: #08c;
    text-decoration: none;
}
/************ Taken from bootstrap ***************/
.alert {
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-error {
    color: #b94a48;
    background-color: #f2dede;
    border-color: #eed3d7;
}
/*************************************************/

/*********** Taken from theme-base.css ***********/
/* TODO: Either theme-base.css should be included
    on this page, or these styles need to be shared
    at a higher level; they should not be duplicated */
.blockUI.blockMsg {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    background: #fff;
}
.blockUI.blockMsg h1 {
    margin: 0;
    padding: 15px 20px 15px 75px;
    background: url('/Alpha/static/custom/common/images/spinner_40px.gif') 15px center no-repeat;
    font-size: 30px;
    text-align: center;
    font-style: italic;
    font-weight: normal;
    line-height: 40px;
    color: #000;
    white-space: nowrap;
}
/*********** end of theme-base.css ***************/
.alpha-page-footer {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 42px;
    color: #fff;
    font-size: 12px;
    line-height: 42px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
#loginButtonDiv, #forgotButtonDiv{
    padding: 7px 30px;
    margin-top: 12px;
}
.user-login-div{
    height: 55px;
    background-color: #efefef;
    margin-top: 4px;
    border-bottom: 1px solid #bfbfbf;
    border-top: 1px solid #bfbfbf;
}
.user-login-div-text{
    font-size: 24px;
    margin-left: 2%;
    color:#00548b;
    position: relative;
    top: 8px;
}
.user-login-header{
    height:35px;
}
.login-image-div{
    margin-top:25px;
}
.user-password-div{
    margin-top:35px;
}
.error-section{
    color: #FFF;
    background-color: #970000;
    font-weight: bold;
    height: 120px;
}
.error-section .alert{
    margin-bottom: 0;
}

.error-section .errortext {
    padding:10px;
    position: relative;
    top: 22px;
}
.form-container{
    padding: 20px 20px 62px 20px;
}


/****** new css for login page ******/

.heading-title {
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
}

.form-signin h2 {
    font-size: 20px;
    font-weight: bold;
    margin-left: 15px;
}

.login-label {
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    text-align: right;
    padding-top: 15px;
}

.login-box {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
}

.login-box .shadow {
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
}

.login-form-div {
    padding:0;
    background: url('/Alpha/static/custom/login/images/login_section.png') repeat-y;
    color: #ffffff;
    width: 33%;
}

.login-links a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 400;
    font-size: 12px;
}

.login-links a:hover {
    color: #4db0ee;
    text-decoration: underline;
}

.form-newuser {
    padding: 0px 15px 0px 15px;
}

.form-newuser h2{
    font-size: 20px;
    font-weight: bold;
    margin-left: 0px;
}

.form-newuser select {
    color: #000000;
    font-weight: 400;
}

.form-field-nu input, button, select, textarea {
    height: 35px;
    width: 100%;
}

.forgotinfo {
    font-size: 14px;
    font-weight: 400;
    position: relative;
    left: 16px;
    width: 98%;
}

.login-box .btn-new-users, .btn-login {
    float: right;
    position: relative;
    left: -2%;
    top: 20px;
}

.login-box .btn-login {
    left: -4%;
    top: 0px;
}

.restore-tab-label {
    font-size: 12px;
    padding-left: 5px;
    font-weight: 400;
}

.restore-tab-label body {
    font-size: 12px;
}

.cookieinfo {
    margin: 10px 0 10px 0;
    font-size: 14px;
    font-weight: 400;
}

.cookieinfo h1 {
    font-size: 16px;
    font-weight: bold;
}

.cookieinfo a {
    color: #ffffff;
    text-decoration: none;
}

.cookieinfo a:hover {
    color: #298dcb;
    text-decoration: underline;
}

.cookietrust {
    margin: 12px 0 12px 0;
    font-size: 14px;
    font-weight: 400;
}

.marketingpage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/****** accordion ********/

.privacy-info-box {
    position: fixed;
    bottom: 0;
    width: 100%;
}

.contentpolicy {
    max-height: 100%;
    margin: 0 auto -50px;
}

.push {
    height: 110px;
    width: 67.1%;
}

.login-privacy h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 10px;
}

.login-privacy {
    font-size: 12px;
}

.login-privacy p {
    margin: 10px;
}

.privacyinfo {
    padding: 5px;
    height: 300px;
    position: absolute;
    bottom: 40px;
}

.privacypolicyinfo {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 200px;
    border: 1px solid #d7d7d7;
}

.privacypolicyinfo a {
    font-size: 12px;
}

.privacypolicyinfo h2 {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
}

.load-privacy {
    cursor: pointer;
    margin: 0px;
    padding: 4px;
    background: #298dcb;
    text-align: center;
    font-family: sans-serif;
    z-index: 99999;
    position: relative;
    bottom: -40px;
    color: #fff;
}

/****** accordion end ********/

/****** new css for login page end ******/


/******* media types *********/


/* Tablets, iPads (portrait) ---------- */
@media screen and (min-width: 640px){

    .privacyinfo {
        height: 260px;
    }

    .privacypolicyinfo {
        height: 120px;
    }
}

/* Tablets, iPads (portrait) ---------- */
@media screen and (min-width: 768px){

    .privacyinfo {
        height: 260px;
    }

    .privacypolicyinfo {
        height: 130px;
    }

    .error-section .errortext {
        top: 10px;
        left: -4px;
        font-size: 10px;
    }
}

/* Tablets, iPads (landscape) ---------- */
@media screen and (min-width: 1024px){
    .privacypolicyinfo {
        height: 160px;
    }

    .error-section .errortext {
        top: 22px;
        font-size: 12px;
    }
}

/* Desktops and laptops ---------- */
@media screen and (min-width: 1280px) and (max-width: 1440px) {

    .privacyinfo {
        height: 300px;
    }

    .privacypolicyinfo {
        height: 200px;
    }
}

@media screen and (max-width: 991px) {
    /* This is where Bootstrap places the button column on its own row */
    .review-details {
        margin-top: 10px;
    }
}

.internet-explorer-banner {
    min-height: 60px;
    background-color: #298dcb;
    color:white;
    z-index: 2;
    top:65px;
    position: absolute;
    width: 67%;
    text-align: center;
    padding-top: 0 !important;
}

.internet-explorer-banner button.close{
    text-align: right;
    color:#FFFFFF;
    opacity: 100;
}

/***** media types end ***************/
